<template>
    <v-dialog v-model="dialog" persistent max-width="1000px">
        <v-card :loading="dialogLoading">
            <v-card-title class="pa-0">
                <v-toolbar color="primary" elevation="0" dark>
                    <span class="">{{
                        (editedIndex === -1 ? $t("add") : $t("edit")) +
                            " " +
                            $t("users.user")
                    }}</span>
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    persistent-hint
                                    v-model="editedItem.userName"
                                    :rules="[rules.required]"
                                    :label="$t('users.userName')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.email"
                                    :rules="[rules.required, rules.email]"
                                    :label="$t('email')"
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="editedIndex == -1" cols="12" md="6">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.password"
                                    :label="$t('users.password')"
                                    :rules="[rules.required]"
                                    type="password"
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="editedIndex == -1" cols="12" md="6">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    type="password"
                                    v-model="editedItem.confirmPassword"
                                    :rules="[
                                        rules.required,
                                        rules.confirmPassword
                                    ]"
                                    :label="$t('users.confirmPassword')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.phoneNumber"
                                    :label="$t('phone')"
                                ></v-text-field> </v-col
                            ><v-col cols="12" md="6">
                                <v-select
                                    outlined
                                    dense
                                    hide-details
                                    :items="groups"
                                    v-model="editedItem.groupGuid"
                                    :label="$t('groups.groupName')"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="12" v-if="isSuperAdmin">
                                <v-select
                                    outlined
                                    dense
                                    hide-details
                                    :items="branches"
                                    v-model="editedItem.branchGuid"
                                    :label="$t('branches.branchName')"
                                    item-text="branchName"
                                    item-value="branchGuid"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary darken-1"
                    class="white--text"
                    @click="save"
                    :disabled="!valid"
                    :min-width="100"
                    :loading="dialogLoading"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="dialogLoading"
                    text
                    color="red"
                    @click="onClose"
                >
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";

export default {
    props: [
        "onClose",
        "dialog",
        "editedItem",
        "editedIndex",
        "resetValidation",
        "refreshTable",
        "users"
    ],
    watch: {
        resetValidation() {
            this.resetForm();
        }
    },
    created() {
        this.getGroups();
        if (this.isInRole(this.roles.branch.branch)) this.getBranches();
    },
    computed: {
        isSuperAdmin() {
            return this.isInRole(this.roles.branch.branch);
        }
    },
    data() {
        return {
            dialogLoading: false,
            tab: null,
            tabsNames: [
                this.$t("users.ui.userInfo"),
                this.$t("users.ui.permissions")
            ],
            valid: true,
            groups: [],
            branches: [],
            rules: {
                required: value => !!value || this.$t("ThisFieldIsRequired"),
                confirmPassword: () =>
                    this.editedItem.confirmPassword ===
                        this.editedItem.password ||
                    this.$t("users.notMatchPassword"),
                email: v => /.+@.+\..+/.test(v) || this.$t("users.validEmail")
            }
        };
    },
    methods: {
        resetForm() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        save() {
            try {
                var val = this.$refs.form.validate();
                if (val) {
                    if (this.editedIndex > -1) {
                        var updatedItem = this.users[this.editedIndex];
                        updatedItem.userName = this.editedItem.userName;
                        updatedItem.email = this.editedItem.email;
                        updatedItem.phoneNumber = this.editedItem.phoneNumber;
                        updatedItem.branchGuid = this.editedItem.branchGuid;
                        updatedItem.groupGuid = this.editedItem.groupGuid;

                        axios
                            .post("Accounts/UpdateUser", updatedItem)
                            .then(response => {
                                if (
                                    response.data.status ==
                                    this.responseStatus.ok
                                ) {
                                    this.$toast.success(
                                        this.$t(
                                            "operationAccomplishedSuccessfully"
                                        )
                                    );
                                    this.onClose();
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            })
                            .finally(() => {
                                this.dialogLoading = false;
                                this.refreshTable();
                            });
                    } else {
                        axios
                            .post("Accounts/AddUser", this.editedItem)
                            .then(response => {
                                if (
                                    response.data.status ==
                                    this.responseStatus.ok
                                ) {
                                    this.$toast.success(
                                        this.$t(
                                            "operationAccomplishedSuccessfully"
                                        )
                                    );
                                    this.onClose();
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            })
                            .finally(() => {
                                this.dialogLoading = false;
                                this.refreshTable();
                            });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        getBranches() {
            try {
                axios.get("Branches/Get").then(response => {
                    this.branches = response.data.data;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getGroups() {
            try {
                axios.get("Accounts/GetGroups").then(response => {
                    this.groups = response.data.data;
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
