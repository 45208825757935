<template>
    <v-container fluid>
        <v-card>
            <Page-Header :title="$t('users.users')" icon="mdi-account-multiple">
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                class="pa-5"
                :headers="headers"
                :items="users"
                :search="search"
                :items-per-page="10"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <!-- user dialog here -->
                    <User-Dialog
                        :key="dialog"
                        :onClose="close"
                        :dialog="dialog"
                        :editedItem="editedItem"
                        :editedIndex="editedIndex"
                        :resetValidation="resetValidation"
                        :refreshTable="getData"
                        :users="users"
                    />
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                    ></confirm-dialog>
                    <v-dialog
                        v-model="dialogChangePassword"
                        persistent
                        max-width="400px"
                    >
                        <v-form ref="form1" v-model="valid1" lazy-validation>
                            <v-card>
                                <v-toolbar color="primary darken-1" dark>
                                    <v-card-title>
                                        <span class="">
                                            {{
                                                $t("users.changePassword")
                                            }}</span
                                        >
                                    </v-card-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    hide-details
                                                    outlined
                                                    dense
                                                    v-model="
                                                        editedItem.password
                                                    "
                                                    :label="
                                                        $t('users.password')
                                                    "
                                                    :rules="[rules.required]"
                                                    type="password"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    hide-details
                                                    outlined
                                                    dense
                                                    type="password"
                                                    v-model="
                                                        editedItem.confirmPassword
                                                    "
                                                    :rules="[
                                                        rules.required,
                                                        rules.confirmPassword
                                                    ]"
                                                    :label="
                                                        $t(
                                                            'users.confirmPassword'
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="primary darken-1"
                                        class="white--text"
                                        @click="saveChangePassword"
                                        :disabled="!valid1"
                                        :min-width="100"
                                        :loading="loading"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        :loading="loading"
                                        color="red"
                                        @click="closeChangePassword"
                                    >
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row no-gutters class="text-end">
                        <v-col
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-if="
                                            isInRole(
                                                roles.administrator
                                                    .administrator
                                            )
                                        "
                                        icon
                                        :loading="loading"
                                        @click="showUserPermissions(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>
                                            mdi-account-details-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{
                                    $t("userPermissions.changePermissions")
                                }}</span>
                            </v-tooltip></v-col
                        >
                        <v-col
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-if="
                                            isInRole(
                                                roles.administrator
                                                    .administrator
                                            )
                                        "
                                        icon
                                        :loading="loading"
                                        @click="changePassword(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>
                                            mdi-key
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("users.changePassword") }}</span>
                            </v-tooltip></v-col
                        >
                        <v-col
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-if="isInRole(roles.user.update)"
                                        icon
                                        :loading="loading"
                                        @click="editItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("edit") }}</span>
                            </v-tooltip></v-col
                        >
                        <v-col
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-if="isInRole(roles.user.delete)"
                                        color="red darken-2"
                                        icon
                                        :loading="loading"
                                        @click="deleteItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>
                                            mdi-delete-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("delete") }}</span>
                            </v-tooltip></v-col
                        >
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import UserDialog from "./UserDialog.vue";

export default {
    components: { ConfirmDialog, PageHeader, UserDialog },
    data() {
        return {
            loading: true,
            valid: true,
            valid1: true,
            dialog: false,
            dialogDelete: false,
            dialogChangePassword: false,
            editedIndex: -1,
            search: "",
            headers: [
                { text: this.$t("users.userName"), value: "userName" },
                { text: this.$t("email"), value: "email" },
                { text: this.$t("phone"), value: "phoneNumber" },
                { text: this.$t("groups.groupName"), value: "groupName" },
                { text: "", value: "actions" }
            ],
            resetValidation: 0,
            users: [],
            groups: [],
            editedItem: {
                userName: "",
                email: null,
                password: "",
                confirmPassword: "",
                phoneNumber: null,
                userGroup: "",
                branchGuid: "",
                groupGuid: ""
            },
            defaultItem: {
                userName: "",
                email: null,
                password: "",
                confirmPassword: "",
                phoneNumber: null,
                userGroup: "",
                branchGuid: "",
                groupGuid: ""
            },
            rules: {
                required: value => !!value || this.$t("ThisFieldIsRequired"),
                confirmPassword: () =>
                    this.editedItem.confirmPassword ===
                        this.editedItem.password ||
                    this.$t("users.notMatchPassword"),
                email: v => /.+@.+\..+/.test(v) || this.$t("users.validEmail")
            }
        };
    },
    computed: {
        isSuperAdmin() {
            return this.isInRole(this.roles.branch.branch);
        }
    },
    created() {
        if (!this.isInRole(this.roles.user.user) && !this.isInRole(this.roles.user.showAll))
            this.redirectUnauthorizedUsers();

        this.loading = true;
        this.getData();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        showUserPermissions(user) {
            this.$router.push({
                name: "userPermissions.userPermissions",
                params: {
                    id: user.id
                }
            });
        },
        editItem(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);

            this.dialog = true;
        },
        changePassword(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);

            this.dialogChangePassword = true;
        },
        deleteItem(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.users[this.editedIndex];
            axios
                .delete("Accounts/DeleteUser?id=" + deleteItem.id)
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }

                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.resetValidation += 1;
        },
        closeChangePassword() {
            this.dialogChangePassword = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form1.reset();
            this.$refs.form1.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            try {
                this.loading = true;
                axios
                    .get("Accounts/GetUsers")
                    .then(response => {
                        this.users = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        saveChangePassword() {
            var val = this.$refs.form1.validate();
            if (val) {
                var updatedItem = this.users[this.editedIndex];
                var changePasswordModel = {
                    id: updatedItem.id,
                    password: this.editedItem.password,
                    confirmPassword: this.editedItem.confirmPassword
                };
                axios
                    .post(
                        "Accounts/ChangeUserPasswordByAdmin",
                        changePasswordModel
                    )
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.closeChangePassword();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }

                        this.getData();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        this.getData();

                        console.log(e);
                    });
            }
        }
    }
};
</script>
